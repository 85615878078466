import React from 'react';
import {graphql} from 'gatsby';

import Product from "../components/Product";

import '../fragments';
import {Helmet} from "react-helmet";
import getProductByUrlKey from "../utils/product";
export default function ProductPage({data, pageContext}) {
    if (pageContext.urlKey === undefined) {
        return null;
    }

    const product = getProductByUrlKey(pageContext.urlKey, data.magento.productDetail.items);

    return (
        <>
            <Helmet>
                <title>{product.name}</title>
            </Helmet>
            <Product
                    product={product}
                    urlKey={pageContext.urlKey}
                    siblingProducts={data.magento.siblingProductsDetail.items}
                />
        </>
    );
}

export const query = graphql`
    query productDetail($urlKey: String, $categorySiblingUrlKeys: [String]) {
        magento {
            productDetail: products(filter: { url_key: { eq: $urlKey } }) {
                items {
                    ...productFields
                    ...bundleProductFields
                    ...configurableProductFields
                    ...groupedProductFields
                    ...relatedProductFields
                }
            }

            siblingProductsDetail: products(filter: { url_key: { in: $categorySiblingUrlKeys } }) {
                items {
                    ...productFields
                    ...bundleProductFields
                    ...configurableProductFields
                    ...groupedProductFields
                }
            }
        }
    }
`
